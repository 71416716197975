var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customerServiceList-page" },
    [
      _c(
        "div",
        { staticClass: "outer" },
        [
          _c("h1", { staticStyle: { "margin-bottom": "30px" } }, [
            _vm._v("商家若有多客服需求，请联系平台申请客服席位"),
          ]),
          _c(
            "nb-table",
            _vm._b(
              {
                scopedSlots: _vm._u([
                  {
                    key: "handles",
                    fn: function ({ row }) {
                      return [
                        row.isBand
                          ? _c(
                              "span",
                              {
                                staticClass: "span",
                                on: {
                                  click: function ($event) {
                                    return _vm.onUnbind(row)
                                  },
                                },
                              },
                              [_vm._v("解绑账号")]
                            )
                          : _c(
                              "span",
                              {
                                staticClass: "span",
                                on: {
                                  click: function ($event) {
                                    return _vm.onBind(row)
                                  },
                                },
                              },
                              [_vm._v("绑定账号")]
                            ),
                        _c("i", { staticClass: "line" }),
                        _c(
                          "span",
                          {
                            staticClass: "span",
                            on: {
                              click: function ($event) {
                                return _vm.onEdit(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                      ]
                    },
                  },
                ]),
              },
              "nb-table",
              _vm.tableInfo,
              false
            )
          ),
        ],
        1
      ),
      _vm.show
        ? _c(
            "el-dialog",
            {
              attrs: { title: _vm.title, visible: _vm.show, width: "530px" },
              on: {
                "update:visible": function ($event) {
                  _vm.show = $event
                },
              },
            },
            [
              _c(
                "nb-form",
                _vm._g(
                  _vm._b(
                    {
                      attrs: { columns: _vm.columns },
                      model: {
                        value: _vm.forms,
                        callback: function ($$v) {
                          _vm.forms = $$v
                        },
                        expression: "forms",
                      },
                    },
                    "nb-form",
                    _vm.formInfo,
                    false
                  ),
                  _vm.formEvents
                )
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }