<template>
  <div class="customerServiceList-page">
    <div class="outer">
      <h1 style="margin-bottom: 30px;">商家若有多客服需求，请联系平台申请客服席位</h1>
      <nb-table v-bind="tableInfo">
        <template #handles="{row}">
          <span v-if="row.isBand" class="span" @click="onUnbind(row)">解绑账号</span>
          <span v-else class="span" @click="onBind(row)">绑定账号</span>
          <i class="line"></i>
          <span class="span" @click="onEdit(row)">编辑</span>
        </template>
      </nb-table>
    </div>

    <el-dialog v-if="show" :title="title" :visible.sync="show" width="530px">
      <nb-form v-model="forms" v-bind="formInfo" :columns="columns" v-on="formEvents"></nb-form>
    </el-dialog>
  </div>
</template>

<script>
import {useCustomerServiceList} from '@/utils/pageConfigs.js';
import {createInput, msgBox} from 'nb-modules';
import {lazy, localGet} from 'ldx-utils';

export default {
  data() {
    useCustomerServiceList.call(this);
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    return {
      forms: {},
      title: '绑定账号', show: false,
      hiddeEdit: false,
      token: '',
      activeRow: {},
      childs: {},
      childUserId: ''
    };
  },
  computed: {
    columns() {
      return [
        {
          use: 'el-select', label: '选择子账号', key: 'supplierUserId', value: '', children: 'el-option', hidden: !this.hiddeEdit,
          request: this.getSelectData, required: true,
          events: {
            change: val => {
              this.childUserId = val;
              this.forms.phone = this.childs[val].userMobile;
            }
          }
        },
        {label: '子账号电话', key: 'phone', value: '', hidden: !this.hiddeEdit},
        createInput('客服名称', 'serviceName', {hidden: this.hiddeEdit, required: true})
      ];
    }
  },
  methods: {
    onUnbind(row) {
      this.activeRow = row;
      msgBox.call(this, '$confirm', {message: '确认解绑账号', title: '提示'}, () => this.bindRelation(0));
    },
    onBind(row) {
      this.title = '绑定账号';
      this.hiddeEdit = true;
      this.show = true;
      this.activeRow = row;
    },
    onEdit(row) {
      this.title = '编辑';
      this.hiddeEdit = false;
      this.forms.serviceName = '';
      this.show = true;
      this.activeRow = row;
    },
    getData() {
      const isSoboten = localGet('isSoboten');
      return new Promise(async resolve => {
        if (!isSoboten || !this.user.isAdmin) {
          resolve({list: []});
          return;
        }
        if (!this.token) {
          const params = {supplierId: this.user.suppliersId};
          const getToken = await this.$axios.get(this.$api.tooth.getToken, {params});
          if (getToken.code === 0) this.token = getToken.data.token;
        }
        const params = {token: this.token, supplierId: this.user.suppliersId}
        const res = await this.$axios.get(this.$api.tooth.queryAgentList, {params});
        if (res.code === 0) {
          const list = res.data;
          resolve({list});
        }
      });
    },
    getSelectData() {
      return new Promise(async resolve => {
        const params = {supplierUserId: this.user.suppliersId};
        const res = await this.$axios.get(this.$api.tooth.getSupplierUserInfo, {params});
        if (res.code === 0) {
          const list = res.data.map(item => ({label: item.userRealName, value: item.userId}));
          this.childs = res.data.reduce((pre, curr) => {
            pre[curr.userId] = curr;
            return pre;
          }, {});
          resolve(list);
        }
      });
    },
    onsubmit() {
      if (this.hiddeEdit) this.bindRelation(1);
      else this.updateServiceInfo();
    },
    bindRelation(isBand) {
      const obj = this.childs[this.childUserId] || {};
      const params = {
        agentId: this.activeRow.agentId, // 客服ID
        isBand, // 是否绑定
        supplierUserId: obj.userId, // 商家用户号
        supplierId: obj.suppliersId
      };
      return new Promise((resolve) => {
        lazy.call(this, async () => {
          const res = await this.$axios.post(this.$api.tooth.bindRelation, params);
          if (res.code === 0) {
            this.show = false;
            this.$refs.nbTable.getData(this.$refs.nbForm);
          }
          resolve();
        });
      });
    },
    updateServiceInfo() {
      const params = {
        customerCode: this.user.suppliersId, // 商户在平台里面的唯一 id
        email: this.activeRow.agentEmail, // 客服邮箱，唯一
        phoneNo: this.activeRow.supplierPhone || 123, // 手机号
        ...this.forms
      };
      lazy.call(this, async () => {
        const res = await this.$axios.post(this.$api.tooth.updateServiceInfo, params);
        if (res.code === 0) {
          this.show = false;
          this.$refs.nbTable.getData(this.$refs.nbForm);
        } else {
          this.$refs.nbForm.resetLoad();
        }
      });
    }
  }
}
</script>
